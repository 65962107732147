import { reactive } from "vue";

export const store = reactive({
    
    follow_claimed: false,
    retweet_claimed: false,
    channel_claimed: false,

    retweet_link: '',
    
    daily_login_count: 0,
    daily_login_last: '',
    today: '',

    loaded: false,
    updates: 0,
    last_sent_update: 0,
    sending: false,
    last_sent: null,
    
    total_balance: 1000,
    balance: 1000,

    
    energy_limit: 1000,
    energy_limit_level: 1,
    energy_balance: 1000,
    recharge_speed: 1,
    multitap: 1,

    
    user_id: '',
    telegram_id: '',
    referrals: 0,


    multitap_upgrades: [
        0, 1000, 5000, 10000, 20000, 30000, 40000, 50000, 100000, 200000
    ],
    recharge_speed_upgrades: [
        0, 5000, 7500, 15000, 30000, 60000, 100000, 150000, 200000, 300000
    ],
    energy_limit_upgrades:[
        {
            'coin': 0,
            'limit': 1000
        },
        {
            'coin': 1000,
            'limit': 1500
        },
        {
            'coin': 5000,
            'limit': 2000
        },
        {
            'coin': 10000,
            'limit': 2500
        },
        {
            'coin': 20000,
            'limit': 3000
        },
        {
            'coin': 30000,
            'limit': 3500
        },
        {
            'coin': 40000,
            'limit': 4000
        },
        {
            'coin': 50000,
            'limit': 4500
        },
        {
            'coin': 100000,
            'limit': 5000
        },
        {
            'coin': 100000,
            'limit': 5500
        }
    ],


    cards: [
        {
            name: 'Land',
            description: 'Your space in the metaverse',
            level: 1,
            ppa: 10,
            upgrades: [
                {
                    level: 1,
                    coin: 500,
                    ppa: 100,
                    requirement: null
                },
                {
                    level: 2,
                    coin: 1000,
                    ppa: 200,
                    requirement: null
                },
                {
                    level: 3,
                    coin: 2000,
                    ppa: 300,
                    requirement: null
                },
            ]
        }
    ]
})