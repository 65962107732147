<template>
    <div class="invite">
        <BackButton @click="router.go(-1)" />
        
        <div class="invite_main">
            <div>
                <div class="balance">
                    <coin_vue :width="'40px'"/>
                    <div class="balance_no">{{ store.balance.toLocaleString() }}</div>
                </div>
                <div class="text">Your Balance</div>
            </div>
            <div>
                <div class="title">Referral Link</div>
                <div class="box" @click="copy()">
                    <div>
                        <div class="link">
                            https://t.me/greenclim_bot?start={{ store.telegram_id }}
                        </div>
                        <div class="bottom">Click to copy</div>
                    </div>
                </div>
            </div>
            <div>
                <div class="title">Your Referrals ({{referrals.length}})</div>
                <loading_vue class="loading" v-if="loading"/>
            </div>

        </div>
        <nav_vue/>
    </div>
</template>

<script setup>

import { BackButton } from 'vue-tg'

import nav_vue from '@/components/nav.vue';
import coin_vue from '@/components/coin.vue';

import loading_vue from '@/components/loading.vue';

import { toast } from 'vue3-toastify';
import { store } from '@/store/store';

import { useRouter } from 'vue-router';
import { inject, onMounted, ref } from 'vue';

const router = useRouter()
const axios = inject('axios')

const copy = () => {
    let text = `https://t.me/greenclim_bot?start=${store.telegram_id}`
    
    navigator.clipboard.writeText(text).then(function() {
        toast.success('Copied')
    });
}

const referrals = ref([])
const loading = ref(false)

onMounted(() => {
    
    axios.post('/user/referrals', {
        user_id: store.user_id
    }).then(data => {
        let r = data.data
        if (r.status == 'ok') {
            referrals.value = r.referrals
            loading.value = false
        }
    })
})

</script>

<style scoped>
.invite {
    height: 100vh;

    display: flex;
    flex-direction: column;

}
.invite_main {
    flex: auto;

    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.balance {
    display: grid;
    grid-template-columns: max-content auto;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.balance_no {
    font-size: 40px;
    font-weight: 100;
    text-align: center;
}
.text {
    text-align: center;
    font-size: 14px;
    /* font-weight: 500; */
    margin-top: 10px;
}

.title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}


.box {
    margin-bottom: 5px;

    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border-radius: 10px;
    background-color: #0f191f;

    fill: white;
}
.link {
    font-weight: 500;
}
.bottom {
    border-top: 1px solid #ddd;
    padding: 5px;
    padding-top: 10px;
    text-align: center;
    font-size: 12px;
    margin-top: 15px;
}

.loading {
    width: fit-content;
    margin: 0 auto;
    margin-top: 30px;
}
</style>