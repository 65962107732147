<template>
    <div class="loading">
        <div class="spin"></div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

    .spin {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: white;
        border-left-color: white;
        animation: spin infinite .8s linear;
    }
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>