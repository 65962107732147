<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M644.62-560q8.5 0 14.25-5.76t5.75-14.27q0-8.51-5.75-14.24T644.62-600H500q-8.5 0-14.25 5.76T480-579.97q0 
            8.51 5.75 14.24T500-560h144.62Zm0 200q8.5 0 14.25-5.76t5.75-14.27q0-8.51-5.75-14.24T644.62-400H500q-8.5 
            0-14.25 5.76T480-379.97q0 8.51 5.75 14.24T500-360h144.62ZM360-530.77q20.31 0 34.77-14.46 14.46-14.46 14.46-34.77 
            0-20.31-14.46-34.77-14.46-14.46-34.77-14.46-20.31 0-34.77 14.46-14.46 14.46-14.46 34.77 0 20.31 14.46 34.77 
            14.46 14.46 34.77 14.46Zm0 200q20.31 0 34.77-14.46 14.46-14.46 14.46-34.77 
            0-20.31-14.46-34.77-14.46-14.46-34.77-14.46-20.31 0-34.77 14.46-14.46 14.46-14.46 34.77 0 20.31 14.46 34.77 
            14.46 14.46 34.77 14.46ZM224.62-160q-27.62 0-46.12-18.5Q160-197 160-224.62v-510.76q0-27.62 18.5-46.12Q197-800 
            224.62-800h510.76q27.62 0 46.12 18.5Q800-763 800-735.38v510.76q0 27.62-18.5 46.12Q763-160 735.38-160H224.62Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>