<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M300.15-466q18.62-37.23 40.54-71.69 21.93-34.46 48.39-67.39l-61.39-12.54q-6.15-1.53-11.92.39t-10.39 
            6.54l-97.07 97.07q-2.31 2.31-1.54 5.39.77 3.08 3.85 4.61L300.15-466Zm458.16-298.85q-92.31 6.62-162.58 40.62-70.27 
            34-132.19 95.92Q419.23-584 386.23-536t-54.38 95.38l113.23 113Q492.46-349 540.85-382q48.38-33 92.69-77.31 61.92-61.92 
            95.92-131.69t40.62-162.08q0-2.46-.35-4.54-.35-2.07-2.58-4.3t-4.3-2.58q-2.08-.35-4.54-.35ZM544.46-539.46q-17.61-17.62-17.61-42.27 
            0-24.65 17.61-42.27 17.62-17.62 42.39-17.62T629.23-624q17.62 17.62 17.62 42.27 0 24.65-17.62 42.27-17.61 17.61-42.38 
            17.61-24.77 0-42.39-17.61Zm-74 244.31 37.62 89.77q1.54 3.07 4.61 3.46 3.08.38 5.39-1.93l97.07-96.3q4.62-4.62 6.54-10.39 
            1.93-5.77.39-11.92l-12.54-61.39q-32.92 26.47-67.39 48.27-34.46 21.81-71.69 40.43Zm337.92-475.54q-.46 
            100-37.92 184.5T658.77-427.46q-3.85 3.84-7.31 6.92-3.46 3.08-7.31 6.92l16.93 82.85q3.23 16.15-1.62 31.31-4.84 
            15.15-16.54 26.84L531.54-161.23q-11.92 11.92-29.08 8.81-17.15-3.12-23.61-19.27l-47.93-113-141.77-142.54-113-47.92q-16.15-6.47-19-23.62-2.84-17.15 9.08-29.08l111.39-111.38q11.69-11.69 26.96-16.15 15.27-4.47 31.42-1.24l82.85 16.93q3.84-3.85 6.53-6.93 2.7-3.07 6.54-6.92 74.23-74.23 158.73-112.08 84.5-37.84 184.5-38.3 6.47.23 12.54 2.57 6.08 2.35 11.31 7.58t7.19 10.92q1.96 5.7 2.19 12.16ZM204.69-313.31q23.46-23.46 57.04-23.19 33.58.27 57.04 23.73t23.35 57.04q-.12 33.58-23.58 57.04-33.39 33.38-78.5 42-45.12 8.61-91.12 13 4.39-46.77 13.39-91.5t42.38-78.12Zm28.54 29.08q-17.77 17.77-24.65 41.77-6.89 24-10.12 49.77 25.77-3.23 49.77-10.39 24-7.15 41.77-24.92 12-12 12.23-28.62.23-16.61-11.77-28.61t-28.61-11.5q-16.62.5-28.62 12.5Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>