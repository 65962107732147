<template>
    <div class="app">
        <router-view v-if="show_app"/>
    </div>
</template>

<script setup>

import { store } from './store/store';


import { useWebAppTheme } from 'vue-tg'
const theme = useWebAppTheme()
theme.setHeaderColor('#1b2932')

import { useWebAppViewport } from 'vue-tg'
const appViewport = useWebAppViewport()
appViewport.expand()


import { ref, inject, onUnmounted, watch } from 'vue';
import { useRoute, useRouter } from "vue-router";
const route = useRoute()
const router = useRouter()


const show_app = ref(false)
const init = () => {

    setTimeout(() => {
        
        
        if (!route.query.userid) {
            let user_id = localStorage.getItem('user_id')

            router.push({
                path: '/',
                query: {
                    'userid': user_id ? user_id : ''
                }
            })

        }
        show_app.value = true
        
    }, 1000);
}
init()


let increase_guage = null
let update_interval = null

onUnmounted(() => {
    clearInterval(increase_guage)
    clearInterval(update_interval)
})


const axios = inject('axios')

watch(() => store.loaded, () => {
    
    if (store.loaded) {
        if (increase_guage != null) {
            clearInterval(increase_guage)
        }
        if (update_interval != null) {
            clearInterval(update_interval)
        }
        increase_guage = setInterval(() => {
            
            if (store.energy_balance < store.energy_limit) {
                store.energy_balance += store.recharge_speed

                store.updates++
            } else {
                store.energy_balance = store.energy_limit
            }
        }, 1000);

        update_interval = setInterval(() => {
            if (store.updates > store.last_sent_update) {
                let current_update = store.updates

                axios.post('/user/update', {
                    'user_id': store.user_id,
                    'total_balance': store.total_balance,
                    'balance': store.balance,
                    'energy_balance': store.energy_balance
                }).then(() => {
                    store.last_sent_update = current_update
                })
            }
        }, 5000);
    }
})


function update_user_balance() {

}


watch(() => store.updates, () => {

    if (store.pause_emiting) {
        return
    }
    update_user_balance()
}, { deep: true })




</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#app {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
:root {
    --color-1: #2DC653;
}
body {
    background-color: #1b2932;
    padding: 0px;
    margin: 0px;

    user-select: none;
}
a {
    color: inherit;
    fill: inherit;
    text-decoration: none;
}
input {
    font-family: "Ubuntu Sans", sans-serif;
    font-size: 16px;
}

.app {
    background-color: #1b2932;
    color: white;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
}
</style>
