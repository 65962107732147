<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M446.69-120q-32.61 0-62.11-13.54t-48.81-39.38L155.54-411q-4.16-5.92-3.92-12.65.23-6.73 5.15-11.66 10-11 
            23.77-13.46 13.77-2.46 26.23 4.85l120.92 59.61v-304.92q0-8.5 5.76-14.25t14.27-5.75q8.51 0 14.36 5.75 5.84 5.75 
            5.84 14.25v190.77h295.16q40.38 0 68.65 28.27Q760-441.92 760-401.54v141.49q0 58.36-41.35 99.2Q677.3-120 
            619.23-120H446.69ZM469-599.23q-8.54 0-14.27-5.75T449-619.23q0-2.77 2.69-10 8-14 12-28.5t4-31.5q0-50-35-85t-85-35q-50 
            0-85 35t-35 85q0 17 4 31.5t12 28.5q1.62 2.5 2.16 5.01.53 2.5.53 5.01 0 8.52-5.58 14.25t-14.22 5.73q-5.58 
            0-10.21-2.92-4.63-2.93-7.06-7.31-10.69-18.16-16.16-38.23-5.46-20.07-5.46-41.75 0-66.64 46.58-113.21 46.58-46.58 
            113.42-46.58 66.85 0 113.43 46.58 46.57 46.57 46.57 113.21 0 21.68-5.46 41.75t-16.15 38.23q-2.51 4.38-7.03 
            7.31-4.53 2.92-10.05 2.92Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>