<template>
    <div class="popup">
        <div class="overlay" @click="close()"></div>
        <transition name="popup">
            <div class="box" v-if="show_box">
                <div class="icon">
                    <speed_f_vue :size="'50px'"/>
                </div>
                <div class="title">Recharge Speed</div>
                <div class="text">
                    Increase the number of recharge every seconds
                </div>
                <div class="note">+1 coin for recharge speed for level {{ new_level }}</div>

                <div class="price_wrapper">
                    <coin_vue  :width="'30px'"/> <div class="price">{{coin.toLocaleString()}}</div> · lvl {{new_level}}
                </div>

                <loading_vue class="loading" v-if="loading"/>
                <div class="btn" v-else @click="proceed()">Proceed</div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import coin_vue from '@/components/coin.vue';

import speed_f_vue from '@/icons/f/speed.vue';
import loading_vue from "@/components/loading.vue";

import { onMounted, ref, defineEmits, inject } from 'vue';

import { store } from '@/store/store';
import { toast } from "vue3-toastify";

const axios = inject('axios')

const new_level = ref(0)
const coin = ref(0)
const loading = ref(false)
const show_box = ref(false)

const emit = defineEmits(['close'])

const close = () => {
    if (loading.value) {
        toast.info('Please wait')
    }
    show_box.value = false
    setTimeout(() => {
        emit('close')
    }, 200);
}


onMounted(() => {
    new_level.value = store.recharge_speed + 1
    coin.value = store.recharge_speed_upgrades[store.recharge_speed]
})


const init = () => {
    setTimeout(() => {
        show_box.value = true
    }, 100);
}
init()


const proceed = () => {
    if (store.balance < coin.value) {
        toast.warn('Not enough balance')
        return
    }

    loading.value = true

    axios.post('/user/upgrade/recharge_speed', {
        'user_id': store.user_id
    })
    .then(data => {
        loading.value = false
        let r = data.data
        if (r.status == 'ok') {
            toast.success('Recharge speed upgraded')
            store.balance -= coin.value
            store.recharge_speed = new_level.value

            close()
        }
    })

}


</script>

<style scoped>

.popup {
    position: fixed;
    z-index: 10;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: flex-end;
    
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
}
.box {
    position: relative;
    height: max-content;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #111316;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    gap: 15px;

    padding: 20px;
}
.icon {
    text-align: center;
    fill: #ffd500;
}
.title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.text {
    font-size: 14px;
    text-align: center;
}
.note {
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 12px;
    text-align: center;
}
.btn {
    text-align: center;
    padding: 15px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--color-1);
}
.price_wrapper {
    margin: 20px 0px;
    /* text-align: center; */
    display: flex;
    font-size: 20px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: #ccc;
}
.price {
    font-weight: 800;
    color: white;
}
.loading {
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.popup-enter-active {
    animation: pop .2s linear;
}
.popup-leave-active {
    animation: pop .2s linear reverse;
}

@keyframes pop {
    from {
        bottom: -60vh;
    }
    to {
        bottom: 0px;
    }
}
</style>