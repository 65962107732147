<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M375.38-860v-40h209.24v40H375.38ZM460-415.38h40v-209.24h-40v209.24ZM480-120q-66.31 
            0-124.5-25.04t-101.81-68.65q-43.61-43.62-68.65-101.81Q160-373.69 160-440t25.04-124.5q25.04-58.19 68.65-101.81 
            43.62-43.61 101.81-68.65Q413.69-760 480-760q58.15 0 112.46 20.77 54.31 20.77 99.69 58.77l43.7-43.69 28.3 28.3-43.69 
            43.7q38 45.38 58.77 99.69Q800-498.15 800-440q0 66.31-25.04 124.5t-68.65 101.81q-43.62 43.61-101.81 68.65Q546.31-120 
            480-120Zm0-40q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z"/>
        </svg>
        
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>