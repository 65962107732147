<template>
    <div class="popup">
        <div class="overlay" @click="close()"></div>
        <transition name="popup">
            <div class="box" v-if="show_box">
                <div class="icon">
                    <x_vue :size="'50px'"/>
                </div>
                <div class="title">Join our Telegram channel</div>
                <div class="price_wrapper">
                    <coin_vue  :width="'30px'"/> <div class="price">10,000</div>
                </div>

                <a href="https://t.me/greenclim" target="_blank" v-if="store.channel_claimed">
                    <div class="btn">Proceed</div>
                </a>

                <a href="https://t.me/greenclim" target="_blank" v-else>
                    <div class="btn" @click="show_claim = true">Proceed</div>
                </a>
                
                <div v-if="show_claim">
                    
                    <loading_vue class="loading" v-if="loading"/>
                    <div class="btn" @click="claim()" v-else>Claim Reward</div>
                </div>
                
            </div>

        </transition>
    </div>
</template>

<script setup>
import x_vue from '@/icons/x.vue';
import coin_vue from '@/components/coin.vue';
import loading_vue from "@/components/loading.vue";

import { ref, defineEmits, inject } from 'vue';

import { store } from '@/store/store';
import { toast } from 'vue3-toastify';

const axios = inject('axios')


const loading = ref(false)
const show_box = ref(false)
const show_claim = ref(false)

const emit = defineEmits(['close'])

const close = () => {
    if (loading.value) {
        toast.info('Please wait')
        return
    }
    show_box.value = false
    setTimeout(() => {
        emit('close')
    }, 200);
}

const init = () => {
    setTimeout(() => {
        show_box.value = true
    }, 100);
}
init()


const claim = () => {
    if (store.channel_claimed) return

    loading.value = true
    store.pause_emiting = true

    axios.post('/user/claim_channel_task', {
        'user_id': store.user_id
    }).then(data => {
        let r = data.data
        if (r.status == 'ok') {
            loading.value = false

            store.channel_claimed = true
            store.balance += 10000
            store.total_balance += 10000

            toast.success('+10,000 has been added to your balance')

            close()
        }
    })
}

</script>

<style scoped>

.popup {
    position: fixed;
    z-index: 10;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: flex-end;
    
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
}
.box {
    position: relative;
    height: max-content;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #111316;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    gap: 15px;

    padding: 20px;
}

.icon {
    text-align: center;
    fill: white;
}
.title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}
.note {
    text-align: center;
    font-size: 12px;
    max-width: 70%;
    margin: 0 auto;
}
.price_wrapper {
    margin: 20px 0px;
    /* text-align: center; */
    display: flex;
    font-size: 20px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: #ccc;
}
.price {
    font-weight: 800;
    color: white;
}
input {
    padding: 12px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #ddd;
    color: white;
    outline: none;
}
::placeholder {
    color: white;
}
.btn {
    text-align: center;
    padding: 15px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--color-1);
}

.loading {
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.popup-enter-active {
    animation: pop .2s linear;
}
.popup-leave-active {
    animation: pop .2s linear reverse;
}

@keyframes pop {
    from {
        bottom: -60vh;
    }
    to {
        bottom: 0px;
    }
}
</style>