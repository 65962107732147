<template>
    <div class="popup">
        <div class="overlay" @click="close()"></div>
        <transition name="popup">
            <div class="box" v-if="show_box">
                <div class="icon">
                    <daily_icon :size="'50px'"/>
                </div>
                <div class="title">Daily Reward</div>
                <div class="note">Get daily reward for logging into Greenclim without skipping</div>
                
                <div class="days">
                    <day_vue v-for="(day, i) in days" :key="i" :day="day" :current="current" :to_claim="to_claim" :pos="i"
                    @claimed="init()"/>
                
                </div>

            </div>
        </transition>
    </div>

</template>

<script setup>
import moment from 'moment';

import daily_icon from "@/icons/f/daily.vue";
import day_vue from './daily/day.vue'

import { ref, defineEmits } from "vue";

import { toast } from "vue3-toastify";
import { store } from '@/store/store';

const loading = ref(false)
const show_box = ref(false)



const emit = defineEmits(['close'])

const close = () => {
    if (loading.value) {
        toast.info('Please wait')
        return
    }
    show_box.value = false
    setTimeout(() => {
        emit('close')
    }, 200);
}



const days = ref([
    {
        'no': 1,
        'title': 'Day 1',
        'reward': 500
    },
    {
        'no': 2,
        'title': 'Day 2',
        'reward': 1000
    },
    {
        'no': 3,
        'title': 'Day 3',
        'reward': 2000
    },
    {
        'no': 4,
        'title': 'Day 4',
        'reward': 3000
    },
    {
        'no': 5,
        'title': 'Day 5',
        'reward': 5000
    },
    {
        'no': 6,
        'title': 'Day 6',
        'reward': 10000
    },
    {
        'no': 7,
        'title': 'Day 7',
        'reward': 15000
    },
    {
        'no': 8,
        'title': 'Day 8',
        'reward': 20000
    },
    {
        'no': 9,
        'title': 'Day 9',
        'reward': 30000
    },
    {
        'no': 10,
        'title': 'Day 10',
        'reward': 50000
    }
])
const to_claim = ref(0)
const current = ref(0)

const init = () => {
    
    setTimeout(() => {
        show_box.value = true
    }, 100);


    if (store.today == store.daily_login_last) {

        toast.success('Come back tomorrow')

        to_claim.value = 1000
        current.value = store.daily_login_count

        return
    }

    if (store.daily_login_count == 0) {
        to_claim.value = 0
        current.value = 0
    }
    if (store.daily_login_count > 0) {
        
        let last_claimed = moment(store.daily_login_last)
        let today = moment(store.today)

        let diff = today.diff(last_claimed, 'days')

        console.log('dddd', diff);

        if (diff == 1) {
            to_claim.value = store.daily_login_count
            current.value = store.daily_login_count
        } else {
            to_claim.value = 0
            current.value = 0
        }
    }

    if (store.daily_login_count >= 10) {
        to_claim.value = 0
        current.value = 0
    }
}
init()


</script>

<style scoped>

.popup {
    position: fixed;
    z-index: 10;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: flex-end;
    
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1px);
}
.box {
    position: relative;
    height: max-content;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #111316;
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    gap: 15px;

    padding: 20px;
}

.icon {
    text-align: center;
    fill: white;
}
.title {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}
.note {
    text-align: center;
    font-size: 12px;
    max-width: 70%;
    margin: 0 auto;
}

.days {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
}


.popup-enter-active {
    animation: pop .2s linear;
}
.popup-leave-active {
    animation: pop .2s linear reverse;
}

@keyframes pop {
    from {
        bottom: -60vh;
    }
    to {
        bottom: 0px;
    }
}
</style>