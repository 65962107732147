<template>
    <div class="nav">
        <div class="nav_item" @click="route_to('/home')"
            :class="[route.name == 'home' ? 'a' : '']">
            <div class="icon">
                <home_f_vue :size="'20px'" v-if="route.name == 'home'"/>
                <home_vue :size="'20px'" v-else/>
            </div>
            Home
        </div>
        <div class="nav_item" @click="route_to('/boost')"
            :class="[route.name == 'boost' ? 'a' : '']">
            <div class="icon">
                <boost_f_vue :size="'20px'" v-if="route.name == 'boost'"/>
                <boost_vue :size="'20px'" v-else/>
            </div>
            Boost
        </div>
        <div class="nav_item" @click="route_to('/tasks')"
            :class="[route.name == 'tasks' ? 'a' : '']">
            <div class="icon">
                <task_f_vue :size="'20px'" v-if="route.name == 'tasks'"/>
                <task_vue :size="'20px'" v-else/>
            </div>
            Tasks
        </div>
        <div class="nav_item" @click="route_to('/invite')"
        :class="[route.name == 'invite' ? 'a' : '']">
            <div class="icon">
                <invite_f_vue :size="'20px'" v-if="route.name == 'invite'"/>
                <invite_vue :size="'20px'" v-else/>
            </div>
            Invite
        </div>
        <div class="nav_item" @click="soon()">
            <div class="icon">
                <wallet_vue :size="'20px'"/>
            </div>
            Wallet
        </div>
    </div>
</template>

<script setup>
import home_vue from '@/icons/home.vue';
import home_f_vue from '@/icons/f/home.vue';

import boost_vue from '@/icons/boost.vue';
import boost_f_vue from '@/icons/f/boost.vue';

// import farm_vue from '@/icons/farm.vue';
import task_vue from '@/icons/task.vue';
import task_f_vue from '@/icons/f/task.vue';

import invite_vue from '@/icons/invite.vue';
import invite_f_vue from '@/icons/f/invite.vue';

import wallet_vue from '@/icons/wallet.vue';
import { useRoute, useRouter } from 'vue-router';

import { toast } from 'vue3-toastify';


const route = useRoute()
const router = useRouter()

const route_to = (to) => {
    router.push(to)
}

const soon = () => {
    toast.info('Coming soon!')
}

</script>

<style scoped>
.nav {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    padding: 0px 10px;
    box-sizing: border-box;
    justify-content: space-around;
    background-color: #0f191f;
}
.nav_item {
    padding: 20px 0px;
    fill: white;
    text-align: center;
    font-size: 12px;
    font-weight: 500;

    display: flex;
    flex-direction: column;
    gap: 5px;
}
.a {
    fill: var(--color-1);
    color: var(--color-1);
}
</style>