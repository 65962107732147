<template>
    <div class="home">
        <div class="main_home">
            <div class="main_container">
                <div class="main">
                    <div class="balance">{{store.balance.toLocaleString()}}</div>
                    <div class="level">Level 1</div>

                    <coin_vue class="coin" @tap="tap($event)" :width="'70%'"/>
                    
                    <div class="flex">
                        <div class="guague">
                            <charge_vue :size="'30px'"/>
                            <div class="guague_numbers">
                                <span>{{store.energy_balance }}</span> {{  ' /' }} {{store.energy_limit}}
                            </div>
                        </div>
                        <router-link to="/boost">
                            <div class="boost">
                                <boost_vue :size="'30px'"/>
                                Boost
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="farm">
                <div class="farm_title">Farm</div>
                
                <div class="farm_box">
                    <div class="bottom">
                        <div class="btn">Coming Soon</div>
                    </div>
                </div>
            </div>
        </div>
        <nav_vue/>
        
        <div>
            <plus_one v-for="(data, i) in plus_data" :data="data" :key="data.date" 
                @close="remove_plus_one(i)"/>
        </div>
    </div>
</template>

<script setup>
import nav_vue from '@/components/nav.vue';

import coin_vue from '@/components/coin.vue'

import plus_one from '@/components/plus_one.vue'

import charge_vue from '@/icons/f/charge.vue';
import boost_vue from '@/icons/f/boost.vue';

import { ref } from 'vue';
import { store } from '@/store/store'


const plus_data = ref([])

const remove_plus_one = () => {
    plus_data.value.shift()
}


const tap = (e) => {

    if (store.energy_balance <= 0) { return }
    
    plus_data.value.push({
        'x': e.clientX,
        'y': e.clientY,
        'date': (new Date()).getTime()
    })

    
    store.total_balance += store.multitap
    store.balance += store.multitap

    store.energy_balance -= store.multitap
    
    store.updates++
    
}


</script>

<style scoped>
.home {
    height: 100vh;

    display: flex;
    flex-direction: column;

}
.main_home {
    flex: auto;

    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}
.main_container {
    flex: auto;

    display: flex;
    align-items: center;
    justify-content: center;
}
.main {
    width: 100%;
}
.balance {
    font-size: 40px;
    font-weight: 100;
    text-align: center;
}
.level {
    /* margin-top: 10px; */
    font-size: 14px;
    text-align: center;
    
}
.coin {
    
    margin: 0 auto;
    margin-top: 30px;
}


.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 20px;
}
.flex >div {
    display: flex;
    align-items: center;
    font-size: 14px;
    /* gap: 5px; */
}
.guague {
    fill: #ffd500;
    font-weight: 500;
}
.guague_numbers {
    display: flex;
    align-items: center;
}
.guague span {
    /* font-size: 16px; */
    font-weight: 800;
}
.boost {
    display: flex;
    align-items: center;
    gap: 5px;
    fill: var(--color-1);
    font-weight: 500;
}

.farm_title {
    /* font-size: 18px; */
    font-weight: 500;
}
.farm_box {
    margin-top: 10px;
    height: 150px;
    border-radius: 10px;
    background-color: white;
    width: 100%;

    background: url('../assets/farm.jpg');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;

    position: relative;
}
.bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;

    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}
.btn {
    font-size: 14px;
    box-sizing: border-box;
    padding: 15px;
    text-align: center;
    background-color: white;
    color: #333;
    font-weight: 600;
    border-radius: 10px;
}
</style>