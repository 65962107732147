<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M278.08-453.23q25.23-56.77 57.88-108.15 32.66-51.39 71.73-98.47l-56.61-11.77q-16.16-3.23-31.31 1.24-15.15 
            4.46-26.85 16.15L181.54-542.85q-11.92 11.93-8.81 29.08 3.12 17.15 19.27 23.62l86.08 36.92Zm501.07-353.85q-92.15 
            1.93-173.8 41Q523.69-727 458.69-662q-41.84 41.85-74.11 90.92-32.27 49.08-57.73 102.62-5 10.69-5 21.88 0 11.2 8.46 
            19.66L433-325q8.46 8.46 19.27 8.85 10.81.38 21.5-4.62 53.54-24.69 102.61-57.73 49.08-33.04 90.93-74.88 65-65 
            104.08-146.66 39.07-81.65 41-173.81 0-6.46-2.47-12.15-2.46-5.69-7.69-10.92-5.23-5.23-10.92-7.7-5.69-2.46-12.16-2.46ZM559.77-554.46q-17.62-17.62-17.62-42.66 0-25.03 17.62-42.65 17.61-17.61 42.65-17.61t42.66 17.61q17.61 17.62 17.61 42.65 0 25.04-17.61 42.66-17.62 17.61-42.66 17.61-25.04 0-42.65-17.61Zm-102 281.69 36.92 86.08q6.46 16.15 23.62 19.38 17.15 3.23 29.07-8.69l111.39-111.38q11.69-11.7 16.15-27.35 4.47-15.65 1.23-31.81l-11.53-55.84q-47.31 39.07-98.58 71.73-51.27 32.65-108.27 57.88Zm-251.92-41.38q23.46-23.47 56.92-23.2 33.46.27 56.92 23.73 23.46 23.47 23.46 56.93t-23.46 56.92q-33.38 33.39-78.5 42-45.11 8.62-91.11 13 4.38-46.77 13.5-91.5 9.11-44.73 42.27-77.88Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>