<template>
    <div class="tasks">
        <BackButton @click="router.go(-1)" />

        <div class="tasks_main">
            <div>
                <div class="balance">
                    <coin_vue :width="'40px'"/>
                    <div class="balance_no">{{ store.balance.toLocaleString() }}</div>
                </div>
                <div class="text">Your Balance</div>
            </div>
            <div>
                <div class="title">Daily Tasks</div>

                <div class="box" @click="show_daily = true">
                    <div class="icon yellow">
                        <daily_f_vue :size="'25px'"/>
                    </div>
                    <div class="">
                        <div class="box_title">Daily Reward</div>
                        <div class="box_flex">
                            <coin_vue :width="'20px'"/>
                            +136,500
                        </div>
                    </div>
                    <div class="left">
                        <check_vue :size="'20px'" v-if="store.today == store.daily_login_last"/>
                        <dot_vue :size="'20px'" class="red" v-else/>
                    </div>
                </div>
            </div>
            <div>
                <div class="title">Tasks List</div>
                
                <div class="box" @click="show_x = true">
                    <div class="icon yellow">
                        <x_icon :size="'25px'"/>
                    </div>
                    <div class="">
                        <div class="box_title">Follow our X account</div>
                        <div class="box_flex">
                            <coin_vue :width="'20px'"/>
                            +5,000
                        </div>
                    </div>
                    <div class="left">
                        <check_vue :size="'20px'" v-if="store.follow_claimed"/>
                        <dot_vue :size="'20px'" class="red" v-else/>
                    </div>
                </div>
                
                <div class="box" @click="show_retweet = true">
                    <div class="icon yellow">
                        <x_icon :size="'25px'"/>
                    </div>
                    <div class="">
                        <div class="box_title">Retweet our X post</div>
                        <div class="box_flex">
                            <coin_vue :width="'20px'"/>
                            +2,000
                        </div>
                    </div>
                    <div class="left">
                        <check_vue :size="'20px'" v-if="store.retweet_claimed"/>
                        <dot_vue :size="'20px'" class="red" v-else/>
                    </div>
                </div>
                <div class="box" @click="show_telegram = true">
                    <div class="icon yellow">
                        <telegram_icon :size="'25px'"/>
                    </div>
                    <div class="">
                        <div class="box_title">Join us on Telegram</div>
                        <div class="box_flex">
                            <coin_vue :width="'20px'"/>
                            +10,000
                        </div>
                    </div>
                    <div class="left">
                        <check_vue :size="'20px'" v-if="store.channel_claimed"/>
                        <dot_vue :size="'20px'" class="red" v-else/>
                    </div>
                </div>
            </div>

        </div>
        <nav_vue/>

        <daily_vue v-if="show_daily" @close="show_daily = false"/>
        <x_vue v-if="show_x" @close="show_x = false"/>
        <retweet_vue v-if="show_retweet" @close="show_retweet = false"/>
        <telegram_vue v-if="show_telegram" @close="show_telegram = false"/>
        
    </div>
</template>

<script setup>

import { BackButton } from 'vue-tg'

import nav_vue from '@/components/nav.vue';
import coin_vue from '@/components/coin.vue';

import daily_f_vue from '@/icons/f/daily.vue';
import x_icon from '@/icons/x.vue';
import telegram_icon from '@/icons/telegram.vue';

import check_vue from '@/icons/check.vue';
import dot_vue from '@/icons/dot.vue';

import daily_vue from '@/components/tasks/daily.vue';
import x_vue from '@/components/tasks/x.vue';
import retweet_vue from '@/components/tasks/retweet.vue';
import telegram_vue from '@/components/tasks/telegram.vue';

import { store } from '@/store/store';

import { useRouter } from 'vue-router';
import { ref } from 'vue';

const router = useRouter()

const show_daily = ref(false)
const show_x = ref(false)
const show_retweet = ref(false)
const show_telegram = ref(false)

</script>

<style scoped>
.tasks {
    height: 100vh;

    display: flex;
    flex-direction: column;

}
.tasks_main {
    flex: auto;

    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.balance {
    display: grid;
    grid-template-columns: max-content auto;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.balance_no {
    font-size: 40px;
    font-weight: 100;
    text-align: center;
}
.text {
    text-align: center;
    font-size: 14px;
    /* font-weight: 500; */
    margin-top: 10px;
}

.title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}


.box {
    margin-bottom: 5px;

    display: flex;
    align-items: center;
    gap: 20px;
    padding: 15px;
    border-radius: 10px;
    background-color: #0f191f;

    fill: white;
}
.icon {
    transform: scale(1.3);
}
.yellow {
    fill: #fff;
}
.box_title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}
.box_flex {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}

.left {
    margin-left: auto;

}
.red {
    fill: tomato;
}
</style>