import { createRouter, createWebHistory } from 'vue-router'
import splash from '../views/splash.vue'

import Home from '../views/Home.vue'
import boost from '../views/boost.vue'
import tasks from '../views/tasks.vue'
import invite from '../views/invite.vue'

const routes = [
    {
        path: '/',
        name: 'splash',
        component: splash
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/boost',
        name: 'boost',
        component: boost
    },
    {
        path: '/tasks',
        name: 'tasks',
        component: tasks
    },
    {
        path: '/invite',
        name: 'invite',
        component: invite
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
