<template>
    <div class="boost">
        <BackButton @click="router.go(-1)" />
        <div class="boost_main">
            <div>
                <div class="balance">
                    <coin_vue :width="'40px'"/>
                    <div class="balance_no">{{ store.balance.toLocaleString() }}</div>
                </div>
                <div class="text">Your Balance</div>
            </div>
            <div>
                <div class="title">Tap Boosters</div>

                <div class="box" @click="show_multitap = true">
                    <div class="icon yellow">
                        <tap_f_vue :size="'35px'"/>
                    </div>
                    <div class="">
                        <div class="box_title">Multitap</div>
                        <div class="box_flex">
                            <coin_vue :width="'20px'"/>
                            {{store.multitap_upgrades[store.multitap].toLocaleString()}} · lvl {{store.multitap + 1}}
                        </div>
                    </div>
                </div>
                <div class="box" @click="show_recharge = true">
                    <div class="icon yellow">
                        <speed_f_vue :size="'35px'"/>
                    </div>
                    <div class="">
                        <div class="box_title">Recharge Speed</div>
                        <div class="box_flex">
                            <coin_vue :width="'20px'"/>
                            {{store.recharge_speed_upgrades[store.recharge_speed].toLocaleString()}} · lvl {{store.recharge_speed + 1}}
                        </div>
                    </div>
                </div>
                <div class="box" @click="energy_limit = true">
                    <div class="icon yellow">
                        <charge_f_vue :size="'35px'"/>
                    </div>
                    <div class="">
                        <div class="box_title">Enery Limit</div>
                        <div class="box_flex">
                            <coin_vue :width="'20px'"/>
                            {{store.energy_limit_upgrades[store.energy_limit_level]['coin'].toLocaleString()}} · lvl {{store.energy_limit_level + 1}}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="title">Farm Boosters</div>
                <div class="soon">
                    <soon_vue :size="'50px'"/>
                    <div class="">Coming Soon</div>
                </div>
            </div>

        </div>
        <nav_vue/>

        <multitap_vue v-if="show_multitap" @close="show_multitap = false"/>
        <recharge_speed_vue v-if="show_recharge" @close="show_recharge = false"/>
        <energy_limit_vue v-if="energy_limit" @close="energy_limit = false"/>
    </div>
</template>

<script setup>

import { BackButton } from 'vue-tg'

import nav_vue from '@/components/nav.vue';
import coin_vue from '@/components/coin.vue';

import tap_f_vue from '@/icons/f/tap.vue';
import speed_f_vue from '@/icons/f/speed.vue';
import charge_f_vue from '@/icons/f/charge.vue';

import soon_vue from '@/icons/soon.vue';

import multitap_vue from '@/components/boosters/multitap.vue';
import recharge_speed_vue from '@/components/boosters/recharge_speed.vue'
import energy_limit_vue from '@/components/boosters/energy_limit.vue';

import { store } from '@/store/store';
import { ref } from 'vue';

import { useRouter } from 'vue-router';
const router = useRouter()

const show_multitap = ref(false)
const show_recharge = ref(false)
const energy_limit = ref(false)

</script>

<style scoped>
.boost {
    height: 100vh;

    display: flex;
    flex-direction: column;

}
.boost_main {
    flex: auto;

    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.balance {
    display: grid;
    grid-template-columns: max-content auto;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.balance_no {
    font-size: 40px;
    font-weight: 100;
    text-align: center;
}
.text {
    text-align: center;
    font-size: 14px;
    /* font-weight: 500; */
    margin-top: 10px;
}

.title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}
.box {
    margin-bottom: 5px;

    display: flex;
    align-items: center;
    gap: 12px;
    padding: 15px;
    border-radius: 10px;
    background-color: #0f191f;

    fill: white;
}
.icon {
    transform: scale(1.3);
}
.yellow {
    fill: #ffd500;
}
.box_title {
    /* font-size: 14px; */
    font-weight: 700;
    margin-bottom: 5px;
}
.box_flex {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
}


.soon {
    text-align: center;
    margin-top: 50px;
    fill: white;
    font-size: 12px;
    opacity: .5;
}
</style>