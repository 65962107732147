<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M540-112.31v-135.38L693.85-280 540-312.31v-135.38L875.38-280 540-112.31ZM200-160q-16.85 0-28.42-11.58Q160-183.15 
            160-200v-158.15q34.15-10 57.08-37.81Q240-423.77 240-460q0-36.23-22.92-64.04-22.93-27.81-57.08-37.81V-720q0-16.85 
            11.58-28.42Q183.15-760 200-760h160q10.77-34.31 37.85-54.85 27.07-20.54 62.15-20.54t62.15 20.54Q549.23-794.31 
            560-760h160q16.85 0 28.42 11.58Q760-736.85 760-720v293.08L467.69-573.08v334.31q-38.46-2-68.23 19.04-29.77 
            21.04-41.31 59.73H200Z"/>
        </svg>
        
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>