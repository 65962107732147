<template>
    <div class="day" @click="claim()" :class="[
                props.pos < props.current ? 'current' : '', 
                props.pos == props.to_claim ? 'to_claim': '' 
            ]"
        >
        <div class="day_title">{{ props.day.title }}</div>
        <div class="pizza" :class="[loading ? 'rotate': '']">
            <coin_vue :size="'20px'" :style="{'height': '20px'}"/>
        </div>
        <div class="reward">
            {{ props.day.reward.toLocaleString() }}
        </div>
    </div>
</template>

<script setup>
import coin_vue from '@/components/coin.vue';
import { store } from '@/store/store';
import { ref, defineProps, defineEmits, inject } from 'vue';


const props = defineProps(['day', 'pos', 'current', 'to_claim'])
const emit = defineEmits(['claimed'])

const loading = ref(false)

const axios = inject('axios')
import { toast } from "vue3-toastify";

const claim = () => {
    if (loading.value ) {return }

    if (props.pos == props.to_claim) {
        loading.value = true
        

        axios.post('/user/claim_daily_reward', {
            user_id: store.user_id
        }).then(data => {
            let r = data.data
            if (r.status == 'ok') {
                toast.success('Daily reward claim, come back tomorrow')

                loading.value = false

                store.total_balance += r.reward_claimed
                store.balance += r.reward_claimed

                store.daily_login_count = r.daily_login_count
                store.daily_login_last = r.daily_login_last

                emit('claimed')
                
            }
        })
    }
}

</script>

<style scoped>

.day {
    padding: 10px;
    border: 1px solid #4b4f54;
    border-radius: 5px;
    text-align: center;

    display: flex;
    flex-direction: column;
    gap: 5px;

    fill: var(--color-1);
}
.day_title {
    font-size: 12px;
    font-weight: 500;
}
.pizza {
    margin: 0 auto;
    width: 20px;
    height: 20px;
}
.rotate {
    animation: 2s spin infinite linear;

}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.reward {
    font-size: 10px;
}

.current {
    background-color: var(--color-1);
    color: black;
    fill: black;
}

.to_claim {
    background-color: transparent;
    outline: 2px solid var(--color-1);
    fill: var(--color-1);
    color: white;

    transition: .2s;

    animation: 2s click infinite linear;
}
@keyframes click {
    0% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(0.95);
    }
}
</style>