<template>
    <div class="splash">
        <div>
            <div class="image">
                <img src="../assets/greenclim.png"/>
            </div>
            <div class="title">Green Clim</div>

            <loading_vue class="loading" v-if="loading"/>
        </div>
    </div>
</template>

<script setup>
import loading_vue from "@/components/loading.vue";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { store } from "@/store/store";


import { useWebApp } from 'vue-tg'
const initdata = useWebApp()

const route = useRoute()
const router = useRouter()
const axios = inject('axios')

const loading = ref(false)

onMounted(() => {
    
    let platform = initdata.platform

    if (platform == 'ios' || platform == 'android') {
        
        loading.value = true
    
        if (route.query.userid) {
            
            store.user_id = route.query.userid
            
            localStorage.setItem('user_id', route.query.userid)

            axios.post('/user/onboard', {
                'user_id': route.query.userid
            }).then(data => {
                let r = data.data
                if (r.status == 'ok') {
                    store.total_balance = r.total_balance
                    store.balance = r.balance
                    store.multitap = r.multitap

                    store.energy_limit = r.energy_limit
                    store.energy_limit_level = r.energy_limit_level
                    store.energy_balance = r.energy_balance
                    
                    store.follow_claimed = r.follow_claimed
                    store.retweet_claimed = r.retweet_claimed
                    store.channel_claimed = r.channel_claimed

                    store.retweet_link = r.retweet_link
                    
                    store.daily_login_count = r.daily_login_count
                    store.daily_login_last = r.daily_login_last
                    store.today = r.today

                    store.telegram_id = r.telegram_id

                    store.loaded = true

                    
                    let current_time = r.current_time
                    
                    let last_time = r.updated_at
                    
                    const seconds_pass = parseInt((current_time - last_time) / 1000)

                    console.log(seconds_pass);
                    
                    
                    store.energy_balance += seconds_pass * store.recharge_speed
                    
                    if (store.energy_balance > store.energy_limit) {
                        store.energy_balance = store.energy_limit
                    }

                    
                    store.updates++
                    
                    setTimeout(() => {
                        router.push('/home')
                    }, 1500);

                }
            })
        }
    }
})

</script>

<style scoped>
.splash {
    height: 100vh;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

}
.image {
    text-align: center;
}
.image img {
    width: 30%;
}
.title {
    font-weight: 800;
    font-size: 30px;
    margin-top: 20px;
    text-align: center;

}

.loading {
    width: fit-content;
    margin: 20px auto;
}
</style>