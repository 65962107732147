<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M480.15-240q-100.23 0-170.19-69.81Q240-379.63 240-479.85q0-100.23 69.81-170.19Q379.63-720 479.85-720q100.23 
            0 170.19 69.81Q720-580.37 720-480.15q0 100.23-69.81 170.19Q580.37-240 480.15-240Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>