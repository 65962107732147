<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size"  viewBox="0 -960 960 960">
            <path d="M295.77-159q-27.54-1-52.35-14.27-24.8-13.27-46.88-38.81-36.16-41.07-56.35-101.04Q120-373.08 
            120-437.69q0-75.31 28.04-141.39 28.04-66.07 77.04-115.07 49-49 114.69-77.43Q405.46-800 480-800q74.54 0 
            140.23 28.54 65.69 28.54 114.31 77.38 48.61 48.85 77.04 114.77Q840-513.38 840-438.38q0 64.69-20.77 124.38t-57.54 
            102.62q-23.38 26.46-47.84 39.42Q689.38-159 662-159q-14.15 
            0-27.54-3.35-13.38-3.34-26.77-10.03l-56-28q-16.61-8.31-35.11-12.47-18.5-4.15-37.35-4.15-19.61 0-37.73 4.15-18.12 
            4.16-33.96 12.47l-55.23 28q-14.39 7.69-27.89 11.03-13.5 3.35-28.65 2.35ZM480-380q25.31 0 42.65-17.35Q540-414.69 
            540-440q0-8.77-2.65-17.15-2.66-8.39-7.2-15.62L604-568.23q14.62 13.77 25.58 31.35 10.96 17.57 18.27 
            37.65h42q-19.62-71.85-77.66-116.31Q554.15-660 480-660t-132.69 44.58q-58.54 44.57-77.93 116.19h41.24Q330-554 
            376.85-587q46.84-33 103.15-33 25.46 0 48.54 7.23t44 20.15l-74.08 95.93q-4.31-1.54-9.23-2.43-4.92-.88-9.23-.88-25.31 
            0-42.65 17.35Q420-465.31 420-440t17.35 42.65Q454.69-380 480-380Z"/>
        </svg>
        
    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>