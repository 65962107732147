<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" >
            <path d="M644.62-560q8.53 0 14.26-5.73 5.74-5.73 5.74-14.27t-5.74-14.27q-5.73-5.73-14.26-5.73H500q-8.54 0-14.27 
            5.73T480-580q0 8.54 5.73 14.27T500-560h144.62Zm0 200q8.53 0 14.26-5.73 5.74-5.73 
            5.74-14.27t-5.74-14.27q-5.73-5.73-14.26-5.73H500q-8.54 0-14.27 5.73T480-380q0 8.54 5.73 
            14.27T500-360h144.62ZM360-530.77q20.69 0 34.96-14.27T409.23-580q0-20.69-14.27-34.96T360-629.23q-20.69 0-34.96 
            14.27T310.77-580q0 20.69 14.27 34.96T360-530.77Zm0 200q20.69 0 
            34.96-14.27T409.23-380q0-20.69-14.27-34.96T360-429.23q-20.69 0-34.96 14.27T310.77-380q0 20.69 14.27 
            34.96T360-330.77ZM224.62-160q-27.62 0-46.12-18.5Q160-197 160-224.62v-510.76q0-27.62 18.5-46.12Q197-800 
            224.62-800h510.76q27.62 0 46.12 18.5Q800-763 800-735.38v510.76q0 27.62-18.5 46.12Q763-160 
            735.38-160H224.62Zm0-40h510.76q9.24 0 16.93-7.69 7.69-7.69 
            7.69-16.93v-510.76q0-9.24-7.69-16.93-7.69-7.69-16.93-7.69H224.62q-9.24 0-16.93 7.69-7.69 7.69-7.69 16.93v510.76q0 
            9.24 7.69 16.93 7.69 7.69 16.93 7.69ZM200-760v560-560Z"/>
        </svg>


    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>