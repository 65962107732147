<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M406.15-400H286.77q-16.31 0-23.58-14.19t2.12-27.27l225.15-326.16q6.16-8.61 15.23-11.42 9.08-2.81 19.16.89 
            10.07 3.69 14.61 12.15 4.54 8.46 3.31 18.54L514.62-520h143.46q16.77 0 23.81 15.31 7.03 15.31-3.81 
            28.38L427.54-176.15q-6.39 7.61-15.46 9.69-9.08 2.08-17.93-1.85-8.84-3.92-13.5-11.88-4.65-7.96-3.42-18.04L406.15-400Z"/>
        </svg>


    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>